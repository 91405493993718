import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import React from 'react';
const FooterStyled = styled.div`
	background-color: #000;
	color: #fff;
	padding: 1em 3em;
	justify-content: center;
	font-size: 0.9em;
	text-align: center;

	a {
		color: #fff;
		text-decoration: none;
		margin-left: 0.3em;
		margin-right: 0.3em;
	}
`;
const Footer = () => {
	return (
		<FooterStyled>
			Copyright © 2021 Grow My Life Ideas ™ ABN 60006719848 - A wholly owned
			business of Calliston Consultants Pty Ltd. -{' '}
			<a href='/privacy-policy'>Privacy Policy - </a> Site powered by
			<a href='http://www.tektoniclabs.io' target='_blank'>
				Tektonic Labs
			</a>
		</FooterStyled>
	);
};
export default Footer;
