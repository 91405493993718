import React from 'react';
import Header from './header2';
import Footer from './footer';

const Layout = ({ children }) => {
	return (
		<div>
			<Header />
			{children}
			<Footer />
		</div>
	);
};

export default Layout;
