import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import React from 'react';
import { navigate, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const StyledLink = styled((props) => <Link {...props} />)`
	// mobile settings
	@media (max-width: 768px) {
		margin-top: 1em;
	}
	font-size: 18px;
	margin-left: 1em;
	color: rgb(255, 255, 255) !important;
	text-decoration: none;
`;

const NavStyled = styled.div`
	.navbar-light .navbar-nav .nav-link {
		color: #fff !important;
		font-size: 18px;
		margin-left: 1em;
		padding: 0.5em 0.5em;
	}

	.menuLink {
		padding: 0.5em 0;
	}
	.buttonCTA {
		background-color: #4dd6a9;
		background-color: #4dd6a9;
		padding: 0.5em 0.5em;
		border-radius: 4px;
	}

	.navbar-light .navbar-brand {
		color: #fff;
	}

	.navbar-light .navbar-toggler {
		border-color: #ccc0 !important;
		color: rgb(255 255 255 / 0%) !important;
		// background-color: red;
	}
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}
`;
const Header = () => {
	return (
		<NavStyled>
			<Navbar
				expand='lg'
				scrolling
				dark
				fixed='top'
				style={{
					background:
						'linear-gradient(108deg,rgba(122, 116, 228, 1) 39%,rgba(27, 138, 249, 1) 79%)',
					color: '#fff',
				}}
			>
				<Container>
					<Navbar.Brand
						onClick={() => navigate('/')}
						style={{ cursor: 'pointer' }}
					>
						<StaticImage
							src='../images/logo/GML_White_Logo.png'
							height={60}
							placeholder='blurred'
							alt='Grow My Life Logo'
						/>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls='basic-navbar-nav' />
					<Navbar.Collapse id='basic-navbar-nav'>
						<Nav className='ms-auto'>
							{/* <StyledLink to='/activities'>Activities</StyledLink> */}
							<StyledLink to='/practitioner' className='menuLink'>
								{' '}
								For Practitioners
							</StyledLink>
							<StyledLink to='/enterprise' className='menuLink'>
								For Enterprise
							</StyledLink>
							<StyledLink to='/contact' className='menuLink'>
								{' '}
								Get in touch
							</StyledLink>
							<StyledLink
								to='https://app.growmylife.com.au'
								className='buttonCTA'
							>
								Try the app for free
							</StyledLink>
							{/* <Nav.Link
							// onClick={() => navigate('/activities')}
							> */}
							{/* </Nav.Link> */}
							{/* <Nav.Link onClick={() => navigate('/business')}>
							</Nav.Link> */}
							{/* <Nav.Link onClick={() => navigate('/expert')}>
								For Experts
							</Nav.Link>
							<Nav.Link onClick={() => navigate('/contact')}>
								Get in touch
							</Nav.Link>
							<Nav.Link href='https://app.growmylife.com.au/'>
								Try the app for free
							</Nav.Link> */}

							{/* <NavDropdown title='Dropdown' id='basic-nav-dropdown'>
							<NavDropdown.Item href='#action/3.1'>Action</NavDropdown.Item>
							<NavDropdown.Item href='#action/3.2'>
								Another action
							</NavDropdown.Item>
							<NavDropdown.Item href='#action/3.3'>Something</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item href='#action/3.4'>
								Separated link
							</NavDropdown.Item>
						</NavDropdown> */}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</NavStyled>
	);
};
export default Header;
